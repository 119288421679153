import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { updateUserExperienceData } from "../../../api";
import getToken from "../../../utils/getToken";
import Odds from "../../components/Market/Odds";

const { Paragraph, Title } = Typography;

const ExchangeExplainer = ({ data }) => {
  const user = useSelector((state) => state.user.user);
  const auth0 = useAuth0();

  useEffect(() => {
    const markExchangeExplainerAsViewed = async () => {
      const token = await getToken({ auth0 });
      if (user) {
        if (!user?.experienceData?.exchangeExplainerViewed) {
          let newExperienceData;
          if (!user.experienceData) {
            newExperienceData = { exchangeExplainerViewed: true };
          } else {
            newExperienceData = {
              ...user.experienceData,
              exchangeExplainerViewed: true,
            };
          }
          await updateUserExperienceData({
            token,
            experienceData: newExperienceData,
          });
        }
      }
    };

    markExchangeExplainerAsViewed();
  }, [auth0]);

  return (
    <div>
      {data.firstTime && (
        <div>
          {data.promoAlert && <>{data.promoAlert}</>}
          <p>
            Looks like this is your first time add an exchange sportsbook. Here
            is a quick explainer to help you understand how they work.
          </p>
        </div>
      )}
      <Title level={3}>Exchange Sportsbooks</Title>
      <div>
        <Title level={4}>What do I need to understand?</Title>
        <Paragraph>
          Bookie Beats exchange markets will look a litte different than
          traditional sportsbooks. In the image below you will see two number
          below our traditional odds presentation. The one in{" "}
          <span style={{ color: "green" }}>green</span> is the current liquidity
          available at that price. The other is the odds the exchange will
          display before accounting for the commission.
        </Paragraph>
        <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
          <Odds
            justifyContent="center"
            typeData={{ isMoneyline: true, isYesNo: false }}
            outcome={{
              book: "exampleBook",
              odds: 2.5,
              displayOdds: 2.55,
              liquidity: 1000,
            }}
          />
        </div>
        <Title level={4}>Why is this different?</Title>
        <Paragraph>
          Exchange books work like a marketplace where individuals can offer and
          accept bets on various outcomes of a sporting event. The exchange
          itself doesn’t set odds; instead, it acts as an intermediary,
          facilitating transactions between bettors and earning a commission on
          winning bets.
        </Paragraph>
      </div>
    </div>
  );
};

export default ExchangeExplainer;

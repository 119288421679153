import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Statistic,
  Typography,
  Select,
  Avatar,
  Slider,
  Checkbox,
  Divider,
  Switch,
  notification,
  Alert,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { createCustomerPortalSession } from "../../../api";
import {
  putUserBooks,
  updateUserDiscordNotificationSettings,
  downgradeUserSubscription,
  cancelDowngradeUserSubscription,
  putUserState,
  putUserGlobalToolSettings,
} from "../../../state/ducks/user";
import { setCurrentlyVisible } from "../../../state/ducks/modals";
import getToken from "../../../utils/getToken";
import config from "../../../config";
import PushNotifications from "./components/PushNotifications.component";

const { Text } = Typography;

const BOOK_SELECT_ON = true;
const ALL_SUPPORTED_DEFAULT_BOOKS = [
  "pointsbet",
  "draftkings",
  "kambi",
  "barstool",
  "fanduel",
  "williamhill",
  "pinnacle",
  "betmgm",
  "betfred",
  "fliff",
  "fanatics",
  "hardrock",
];

const ALL_SUPPORTED_BOOKS = [
  ...ALL_SUPPORTED_DEFAULT_BOOKS,
  "prophetx",
  "sporttrade",
];

const ALL_SUPPORTED_BOOKS_FOR_PARTNERS = [...ALL_SUPPORTED_BOOKS, "circa"];

const BOOK_DISPLAY_NAMES = {
  pointsbet: "PointsBet",
  draftkings: "DraftKings",
  kambi: "Kambi (BetRivers, Unibet)",
  barstool: "ESPN BET",
  fanatics: "Fanatics",
  fanduel: "FanDuel",
  williamhill: "Caesars",
  betmgm: "MGM",
  pinnacle: "Pinnacle",
  betfred: "Betfred",
  circa: "Circa",
  fliff: "Fliff",
  hardrock: "Hard Rock",
  prophetx: "ProphetX",
  sporttrade: "Sporttrade",
};

const basicPlanPriceIds = [
  "price_1KkE8WBOlGO7J9ufDWEAyVAF",
  "price_1Jt1qWBOlGO7J9ufc2mkngZz",
];
const premiumPlanPriceIds = [
  "price_1KkE8mBOlGO7J9ufsfuwG87t",
  "price_1Jt1rFBOlGO7J9uf4HNHR5vP",
  "price_1Jt1r1BOlGO7J9ufAfeAPYoF",
];

const LeftDivider = ({ text }) => {
  return <Typography.Title level={4}>{text}</Typography.Title>;
};

const promoAlerts = {
  // prophetx: (
  //   <Alert
  //     message={
  //       <>
  //         Register for <b>ProphetX</b> with{" "}
  //         <b>
  //           <a href="https://prophetx.onelink.me/E5Yi/BOOKIEBEATS">THIS LINK</a>
  //         </b>{" "}
  //         or use promo code <b>BOOKIEBEATS</b> in the app
  //       </>
  //     }
  //     type="info"
  //     target="_blank"
  //     showIcon
  //   />
  // ),
};

const Account = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const location = useLocation();
  const [isSessionPortalLoading, setIsSessionPortalLoading] = useState(false);
  const [booksAdded, setBooksAdded] = useState([]);
  const [isDowngradeSubscriptionLoading, setIsDowngradeSubscriptionLoading] =
    useState(false);
  const [isCancelDowngradeLoading, setIsCancelDowngradeLoading] =
    useState(false);
  const user = useSelector((state) => state.user.user);
  const userUpdateError = useSelector((state) => state.user.error);
  const [userBooks, setUserBooks] = useState(
    user.books || ALL_SUPPORTED_DEFAULT_BOOKS
  );
  const [isUserBooksSaving, setIsUserBooksSaving] = useState(false);
  const [userDiscordNotificationSettings, setUserDiscordNotificationSettings] =
    useState({ ...user.discordNotificationSettings });
  const [isUserStateSaving, setIsUserStateSaving] = useState(false);
  const [
    isUserDiscordNotificationSettingsSaving,
    setIsUserDiscordNotificationSettingsSaving,
  ] = useState(false);
  const { subscriptions } = user;
  let subscription;
  subscriptions.forEach((s) => {
    s.items.data.forEach((item) => {
      if (item.plan.product === "prod_JNLHoMDJwiwqpT") subscription = s;
    });
  });

  useEffect(() => {
    if (userUpdateError) {
      notification.error({
        message: "There was an issue saving your changes",
        description: userUpdateError,
        duration: 10,
      });
    }
  }, [userUpdateError]);

  useEffect(() => {
    if (user.books) {
      setUserBooks(user.books);
    } else {
      if (user.isAdmin || user.isPartner) {
        setUserBooks(ALL_SUPPORTED_BOOKS_FOR_PARTNERS);
      } else {
        setUserBooks(ALL_SUPPORTED_DEFAULT_BOOKS);
      }
    }
    setUserDiscordNotificationSettings({ ...user.discordNotificationSettings });
    setIsUserBooksSaving(false);
    setIsUserDiscordNotificationSettingsSaving(false);
    setIsDowngradeSubscriptionLoading(false);
    setIsCancelDowngradeLoading(false);
    const { hash } = location;
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, [user, location]);

  const userState = useMemo(() => {
    let name;
    for (let i = 0; i < config.states.length; i++) {
      if (user.state === config.states[i].abbreviation) {
        name = config.states[i].name;
        break;
      }
    }
    return name;
  }, [user.state]);

  const promoAlertNodes = useMemo(() => {
    const nodes = [];
    booksAdded.forEach((book) => {
      if (promoAlerts[book]) {
        nodes.push(<div key={book}>{promoAlerts[book]}</div>);
      }
    });
    return nodes;
  }, [booksAdded]);

  const onManageSubscriptionClick = async () => {
    setIsSessionPortalLoading(true);
    try {
      const token = await getToken({ auth0 });
      const { url } = await createCustomerPortalSession({ token });
      window.location.href = url;
    } catch (err) {
      console.error(err);
      setIsSessionPortalLoading(false);
    }
  };

  const onDowngradeSubscriptionClick = async () => {
    setIsDowngradeSubscriptionLoading(true);
    try {
      const token = await getToken({ auth0 });
      dispatch(downgradeUserSubscription({ token }));
    } catch (err) {
      console.error(err);
    }
  };

  const onCancelDowngradeClick = async () => {
    setIsCancelDowngradeLoading(true);
    try {
      const token = await getToken({ auth0 });
      dispatch(cancelDowngradeUserSubscription({ token }));
    } catch (err) {
      console.error(err);
    }
  };

  let date, renewalTitle;
  if (subscription.schedule) {
    const { phases } = subscription.schedule;
    if (
      premiumPlanPriceIds.includes(phases[0]?.items[0]?.plan) &&
      basicPlanPriceIds.includes(phases[1]?.items[0]?.plan)
    ) {
      date = new Date(phases[0].end_date * 1000).toShortFormat();
      renewalTitle = "Downgrades On";
    } else {
      date = new Date(
        phases[0].end_date * 1000 || subscription.current_period_end * 1000
      ).toShortFormat();
      renewalTitle = "Changes On";
    }
  } else {
    if (subscription.cancel_at_period_end) {
      renewalTitle = "Ends On";
      date = new Date(subscription.cancel_at * 1000).toShortFormat();
    } else {
      renewalTitle = "Renews On";
      date = new Date(subscription.current_period_end * 1000).toShortFormat();
    }
  }

  let subscriptionLevelDisplayValue;
  if (user.subscriptionLevel === "PREMIUM") {
    subscriptionLevelDisplayValue = "Premium";
  } else if (user.subscriptionLevel === "PREMIUM_DAY") {
    subscriptionLevelDisplayValue = "Premium Day";
  } else if (user.subscriptionLevel === "BASIC") {
    subscriptionLevelDisplayValue = "Basic";
  } else if (user.subscriptionLevel === "PREMIUM_TRIAL") {
    subscriptionLevelDisplayValue = "Premium Trial";
  }

  const bookSelectOptions = useMemo(() => {
    if (user.isAdmin || user.isPartner) {
      return ALL_SUPPORTED_BOOKS_FOR_PARTNERS;
    }
    return ALL_SUPPORTED_BOOKS;
  }, [user]);

  const isFreeTrial = useMemo(() => {
    // if trail end is later than now
    if (user?.subscriptions[0]?.trial_end) {
      return user.subscriptions[0].trial_end * 1000 > Date.now();
    }
    return false;
  }, [user]);

  return (
    <div style={{ paddingTop: "0.5rem" }}>
      {BOOK_SELECT_ON && (
        <div style={{ marginBottom: "2rem" }}>
          <LeftDivider text="My Books" />
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="select at least one book"
            value={userBooks}
            onChange={(value) => {
              if (value.length > userBooks.length) {
                setBooksAdded((prev) => {
                  const newBook = value[value.length - 1];
                  if (prev.includes(newBook)) {
                    return prev;
                  }
                  if (
                    !user?.experienceData?.exchangeExplainerViewed &&
                    (newBook === "prophetx" || newBook === "sporttrade")
                  ) {
                    dispatch(
                      setCurrentlyVisible({
                        name: "ExchangeExplainer",
                        data: {
                          firstTime: true,
                          promoAlert: promoAlerts[newBook],
                        },
                      })
                    );
                    console.log(user.experienceData);
                  }
                  return [...prev, newBook];
                });
              }
              setUserBooks(value);
            }}
          >
            {bookSelectOptions.map((book) => {
              return (
                <Select.Option
                  key={book}
                  value={book}
                  label={BOOK_DISPLAY_NAMES[book]}
                >
                  {BOOK_DISPLAY_NAMES[book]}
                </Select.Option>
              );
            })}
          </Select>
          {promoAlertNodes.length > 0 && (
            <div style={{ marginTop: "0.5rem" }}>{promoAlertNodes}</div>
          )}
          <Button
            type="primary"
            ghost
            style={{ marginTop: "1rem" }}
            onClick={async () => {
              setIsUserBooksSaving(true);
              const token = await getToken({ auth0 });
              dispatch(putUserBooks({ token, books: userBooks }));
            }}
            loading={isUserBooksSaving}
            disabled={isUserBooksSaving}
          >
            Save
          </Button>
        </div>
      )}
      <Divider />
      <div style={{ marginBottom: "2rem" }}>
        <LeftDivider text="Global Tool Settings" />
        <div>
          <div>
            <Text>Direct Betslip Link (if available)</Text>
            <span
              style={{
                position: "relative",
                top: -11,
                left: 0,
                fontSize: "50%",
                color: "red",
              }}
            >
              BETA
            </span>
          </div>
          <Switch
            defaultChecked={user.globalToolSettings?.useDirectBetslipLink}
            onChange={async (checked) => {
              const token = await getToken({ auth0 });
              dispatch(
                putUserGlobalToolSettings({
                  token,
                  globalToolSettings: { useDirectBetslipLink: checked },
                })
              );
            }}
          />
        </div>
      </div>

      <div id="push-notifications"></div>
      <Divider />
      {user &&
        (["PREMIUM", "PREMIUM_DAY", "PREMIUM_TRIAL"].includes(
          user.subscriptionLevel
        ) ||
          user.isPartner) && (
          <>
            <LeftDivider
              text={
                <span>
                  Live Push Notifications
                  <span
                    style={{
                      position: "relative",
                      top: -11,
                      left: 2,
                      fontSize: "50%",
                      color: "red",
                    }}
                  >
                    ALPHA
                  </span>
                </span>
              }
            />
            <PushNotifications />
            <Divider />
          </>
        )}
      <div style={{ marginBottom: "2rem" }}>
        <LeftDivider text="Discord" />
        {user.discordUserId && user.discordUserId !== "NONE" ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Avatar size={40} src={user.discordAvatar + "?size=80"} />
              </div>
              <div
                style={{
                  paddingLeft: "1rem",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <Text>{user.discordUsername}</Text>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: "0.5rem",
                }}
              >
                <Link to="/account/alert-channels">
                  <Button>
                    Set Alert Channels <RightOutlined />
                  </Button>
                </Link>
              </div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  marginBottom: "0.5rem",
                }}
              >
                <Text>Notification Settings</Text>
              </div>
              <div style={{ fontSize: 16, marginBottom: "0.5rem" }}>
                <Text>Arbitrage</Text>
              </div>
              <div style={{ fontSize: 14 }}>
                <Text>Minimum Percent</Text>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: 200 }}>
                  <Slider
                    min={3}
                    max={10}
                    onChange={(minArb) => {
                      setUserDiscordNotificationSettings({
                        ...userDiscordNotificationSettings,
                        minArb,
                      });
                    }}
                    value={
                      typeof userDiscordNotificationSettings.minArb === "number"
                        ? userDiscordNotificationSettings.minArb
                        : 3
                    }
                    tooltip={{
                      formatter: (v) => `${v}%`,
                    }}
                  />
                </div>
                <div
                  style={{ lineHeight: "32px", marginLeft: "0.5rem" }}
                >{`${userDiscordNotificationSettings.minArb}%`}</div>
              </div>
              <div>
                <Checkbox
                  onChange={({ target }) =>
                    setUserDiscordNotificationSettings({
                      ...userDiscordNotificationSettings,
                      middle: target.checked,
                    })
                  }
                  checked={userDiscordNotificationSettings.middle}
                >
                  Middle
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  onChange={({ target }) =>
                    setUserDiscordNotificationSettings({
                      ...userDiscordNotificationSettings,
                      halfMiddle: target.checked,
                    })
                  }
                  checked={userDiscordNotificationSettings.halfMiddle}
                >
                  Half Midde
                </Checkbox>
              </div>
              <div style={{ fontSize: 16, margin: "0.5rem 0" }}>
                <Text>EV+</Text>
              </div>
              <div style={{ fontSize: 14 }}>
                <Text>Minimum Percent</Text>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: 200 }}>
                  <Slider
                    min={3}
                    max={10}
                    onChange={(minEv) => {
                      setUserDiscordNotificationSettings({
                        ...userDiscordNotificationSettings,
                        minEv,
                      });
                    }}
                    value={
                      typeof userDiscordNotificationSettings.minEv === "number"
                        ? userDiscordNotificationSettings.minEv
                        : 3
                    }
                    tooltip={{
                      formatter: (v) => `${v}%`,
                    }}
                  />
                </div>
                <div
                  style={{ lineHeight: "32px", marginLeft: "0.5rem" }}
                >{`${userDiscordNotificationSettings.minEv}%`}</div>
              </div>
            </div>
            <Button
              type="primary"
              ghost
              style={{ marginTop: "1rem" }}
              onClick={async () => {
                setIsUserDiscordNotificationSettingsSaving(true);
                const token = await getToken({ auth0 });
                dispatch(
                  updateUserDiscordNotificationSettings({
                    token,
                    discordNotificationSettings:
                      userDiscordNotificationSettings,
                  })
                );
              }}
              loading={isUserDiscordNotificationSettingsSaving}
              disabled={isUserDiscordNotificationSettingsSaving}
            >
              Save
            </Button>
          </>
        ) : (
          <Link to="/banter">
            <Button>Join Server</Button>
          </Link>
        )}
      </div>
      <Divider />
      <div style={{ marginBottom: "2rem" }}>
        <LeftDivider text="State / Country" />
        {user.discordUserId && user.discordUserId !== "NONE" ? (
          <div>
            <div>
              <Select
                style={{ width: 175 }}
                showSearch
                onSelect={async (value) => {
                  let abbreviation;
                  for (let i = 0; i < config.states.length; i++) {
                    if (value === config.states[i].name) {
                      abbreviation = config.states[i].abbreviation;
                      break;
                    }
                  }
                  const token = await getToken({ auth0 });
                  dispatch(putUserState({ token, state: abbreviation }));
                }}
                defaultValue={userState}
              >
                {config.states.map((state) => {
                  return (
                    <Select.Option key={state.abbreviation} value={state.name}>
                      {state.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                type="primary"
                ghost
                onClick={async () => {
                  setIsUserStateSaving(true);
                  await new Promise((r) => setTimeout(r, 500));
                  setIsUserStateSaving(false);
                }}
                loading={isUserStateSaving}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Text>
              You must join the Discord server before selecting a state
            </Text>
          </div>
        )}
      </div>
      <Divider />
      <div style={{ marginBottom: "2rem" }}>
        <LeftDivider text="Subscription" />
        <Statistic
          title={"Level"}
          value={subscriptionLevelDisplayValue}
          valueStyle={{ fontSize: "1rem" }}
        />
        <Statistic
          title={"Free Premium Day Passes"}
          value={user.freePremiumDays || 0}
          valueStyle={{ fontSize: "1rem" }}
          style={{ marginTop: "0.5rem" }}
        />
        <Statistic
          title={renewalTitle}
          value={date}
          valueStyle={{ fontSize: "1rem" }}
          style={{ marginTop: "0.5rem" }}
        />
        {renewalTitle !== "Downgrades On" &&
          user.subscriptionLevel === "PREMIUM" && (
            <Button
              onClick={onDowngradeSubscriptionClick}
              style={{ marginTop: "1rem", marginRight: "1rem" }}
              loading={isDowngradeSubscriptionLoading}
              disabled={isDowngradeSubscriptionLoading}
            >
              Downgrade to Basic
            </Button>
          )}
        {renewalTitle === "Downgrades On" &&
          user.subscriptionLevel === "PREMIUM" && (
            <Button
              onClick={onCancelDowngradeClick}
              style={{ marginTop: "1rem", marginRight: "1rem" }}
              loading={isCancelDowngradeLoading}
              disabled={isCancelDowngradeLoading}
            >
              Keep Premium
            </Button>
          )}
        <Button
          onClick={onManageSubscriptionClick}
          loading={isSessionPortalLoading}
          disabled={isSessionPortalLoading}
          style={{ marginTop: "1rem" }}
        >
          Manage Subscription
        </Button>
      </div>
      {user.referralCode && (
        <>
          <Divider />
          <div style={{ marginBottom: "2rem" }}>
            <LeftDivider text="Referral Code" />
            {isFreeTrial || user.isTrialAccount ? (
              <Text style={{ fontSize: "1rem" }}>
                Available at completion of trial period
              </Text>
            ) : (
              <Text style={{ fontSize: "1rem" }} copyable>
                {user.referralCode}
              </Text>
            )}
          </div>
        </>
      )}
      <div>
        <LeftDivider text="Security" />
        <Button
          onClick={() => auth0.logout({ returnTo: window.location.origin })}
          danger
        >
          Log Out
        </Button>
      </div>
    </div>
  );
};

export default Account;

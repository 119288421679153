import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentlyVisible: undefined,
  data: {},
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setCurrentlyVisible(state, action) {
      if (action?.payload?.data) {
        state.currentlyVisible = action.payload.name;
        state.data = action.payload.data;
      } else {
        state.currentlyVisible = action.payload;
      }
    },
    close(state) {
      state.currentlyVisible = undefined;
      state.data = {};
    },
  },
});

const { actions, reducer } = modalsSlice;
export const { close, setCurrentlyVisible } = actions;
export default reducer;

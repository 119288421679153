import React, { useState, useEffect } from "react";
import { Select, Form, Input, Button } from "antd";

const ScoreboardEntry = () => {
  const [websocket, setWebsocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket("wss://scoreboard.bookiebeats.com/ws");
    setWebsocket(ws);

    ws.onopen = () => {
      console.log("Connected to the WebSocket server");
      ws.send(
        JSON.stringify({
          action: "getActiveGames",
        })
      );
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.action === "activeGames") {
        console.log(data.games);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("Disconnected from the WebSocket server");
    };

    // Clean up on component unmount
    return () => {
      ws.close();
    };
  }, []);

  const sendMessage = (message) => {
    // Example: Sending message via WebSocket
    if (websocket && websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(message));
      console.log("Message sent successfully");
    } else {
      console.error("WebSocket is not connected");
    }
  };

  return (
    <div style={{ padding: "1rem 0" }}>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="vertical"
        style={{ maxWidth: 600 }}
        initialValues={{
          gameType: "football",
        }}
        onFinish={(values) => {
          const message = { action: "createGame", data: { ...values } };
          sendMessage(message);
        }}
      >
        <Form.Item name="gameType" label="Sport" required>
          <Select
            style={{ width: 120 }}
            onChange={(value) => console.log(value)}
            options={[
              { value: "football", label: "Football" },
              { value: "basketball", label: "Basketball" },
            ]}
          />
        </Form.Item>
        <Form.Item name="awayTeamName" label="Away Team" required>
          <Input />
        </Form.Item>
        <Form.Item name="homeTeamName" label="Home Team" required>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Start Game
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScoreboardEntry;

import React from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../state/ducks/modals";
import BaseballWarning from "./BaseballWarning/BaseballWarning";
import ArbitrageHelp from "./ArbitrageHelp/ArbitrageHelp";
import LowHoldsHelp from "./LowHoldsHelp/LowHoldsHelp";
import FreeValueHelp from "./FreeValueHelp/FreeValueHelp";
import EVCalculatorHelp from "./EVCalculatorHelp/EVCalculatorHelp";
import PremiumUpgradeCheckout from "./PremiumUpgradeCheckout/PremiumUpgradeCheckout";
import DailyPassCheckout from "./DailyPassCheckout/DailyPassCheckout";
import LiveAlert from "./LiveAlert/LiveAlert";
import ExchangeExplainer from "./ExchangeExplainer/ExchangeExplainer";

const modals = {
  BaseballWarning: () => <BaseballWarning />,
  ArbitrageHelp: () => <ArbitrageHelp />,
  LowHoldsHelp: () => <LowHoldsHelp />,
  FreeValueHelp: () => <FreeValueHelp />,
  EVCalculatorHelp: () => <EVCalculatorHelp />,
  PremiumUpgradeCheckout: () => <PremiumUpgradeCheckout />,
  DailyPassCheckout: () => <DailyPassCheckout />,
  LiveAlert: () => <LiveAlert />,
  ExchangeExplainer: (data) => <ExchangeExplainer data={data} />,
};

const Modals = () => {
  const currentlyVisibleModal = useSelector(
    (state) => state.modals.currentlyVisible
  );
  const data = useSelector((state) => state.modals.data);
  const dispatch = useDispatch();
  return (
    <Modal
      open={currentlyVisibleModal}
      footer={null}
      onCancel={() => dispatch(close())}
    >
      {modals[currentlyVisibleModal] && modals[currentlyVisibleModal](data)}
    </Modal>
  );
};

export default Modals;

import React from "react";
import oddsCoverter from "odds-converter";
import { useTheme } from "../../../../hooks";
import styles from "./LowHoldPlay.module.less";
import SportIcon from "../../../components/SportIcon/SportIcon.component";
import betriversLogo from "../../../../assets/betrivers.svg";
import draftkingsLogo from "../../../../assets/draftkings.svg";
import fanduelLogo from "../../../../assets/fanduel.svg";
import pointsbetLogo from "../../../../assets/pointsbet.svg";
import kambiLogo from "../../../../assets/kambi.svg";
import barstoolLogo from "../../../../assets/espnbet.svg";
import fanaticsLogo from "../../../../assets/fanatics.svg";
import prophetxLogo from "../../../../assets/prophetx.svg";
import hardrockLogo from "../../../../assets/hardrock.svg";
import williamhillLogo from "../../../../assets/caesars.svg";
import pinnacleLogo from "../../../../assets/pinnacle.svg";
import betmgmLogo from "../../../../assets/betmgm.svg";
import betfredLogo from "../../../../assets/betfred.svg";
import circaLogo from "../../../../assets/circa.svg";
import fliffLogo from "../../../../assets/fliff.svg";

const logos = {
  betrivers: betriversLogo,
  kambi: kambiLogo,
  barstool: barstoolLogo,
  fanatics: fanaticsLogo,
  fanduel: fanduelLogo,
  pointsbet: pointsbetLogo,
  williamhill: williamhillLogo,
  draftkings: draftkingsLogo,
  hardrock: hardrockLogo,
  pinnacle: pinnacleLogo,
  betmgm: betmgmLogo,
  betfred: betfredLogo,
  circa: circaLogo,
  fliff: fliffLogo,
  prophetx: prophetxLogo,
};

const formatOdds = (value) => {
  const americanOdds = oddsCoverter.decimal.toAmerican(value);
  const roundedOdds = Math.round(americanOdds);
  if (roundedOdds > 0) return `+${roundedOdds}`;
  return `${roundedOdds}`;
};

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const LowHoldPlay = ({ lowHoldPlay }) => {
  const themeTokens = useTheme();

  const { event, profitDollars, middle } = lowHoldPlay;
  const { participants } = event;
  const { outcomes } = lowHoldPlay;
  const outcome0 = outcomes.find(
    (outcome) =>
      outcome.side === "Away" ||
      outcome.side === "Over" ||
      outcome.side === "Yes"
  );
  if (!outcome0) {
    console.log(lowHoldPlay);
  }
  const outcome1 = outcomes.find(
    (outcome) =>
      outcome.side === "Home" ||
      outcome.side === "Under" ||
      outcome.side === "No"
  );

  let { isOverUnder, isMoneyline, isPlayer, friendlyName, isYesNo } =
    lowHoldPlay.typeData;

  if (isPlayer) {
    friendlyName = friendlyName.replace("%PLAYER%", lowHoldPlay.player);
  }
  if (friendlyName.includes("%HOMETEAM%")) {
    friendlyName = friendlyName.replace(
      "%HOMETEAM%",
      lowHoldPlay.event.homeParticipant
    );
  }
  if (friendlyName.includes("%AWAYTEAM%")) {
    friendlyName = friendlyName.replace(
      "%AWAYTEAM%",
      lowHoldPlay.event.participants[0]
    );
  }

  const d = new Date(event.startTime);

  return (
    <div
      className={styles.freeValuePlay}
      style={{ borderColor: themeTokens.colorBgLayout }}
    >
      <div
        className={styles.freeValuePlayHeader}
        style={{
          borderBottom: middle ? "3px solid gold" : "",
          background: themeTokens.colorBgLayout,
          color: themeTokens.colorTextSecondary,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>{friendlyName}</div>
          {(isOverUnder || isPlayer || isYesNo) && (
            <div className={styles.teams}>
              {`${participants[0]} @ ${participants[1]}`}
            </div>
          )}
          <div className={styles.teams}>
            {d.toLocaleTimeString([], {
              weekday: "short",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            })}
          </div>
        </div>
        <div className={styles.headerButtonContainer}>
          <SportIcon id={lowHoldPlay.sport.id} />
        </div>
      </div>
      <div
        className={styles.freeValuePlayBody}
        style={{ color: themeTokens.colorText }}
      >
        <div className={styles.profit}>
          <div>
            {profitDollars ? usdFormatter.format(profitDollars) : "$---"}
          </div>
          <div className={styles.percent}>{lowHoldPlay.profit}%</div>
        </div>
        <div className={styles.competitorsContainer}>
          <div className={styles.competitorContainer}>
            <div className={styles.competitorName}>
              <div>
                {isOverUnder ? "Over" : isYesNo ? "Yes" : participants[0]}
              </div>
              {outcomes[1].stake && (
                <div className={styles.betAmount}>
                  {usdFormatter.format(outcomes[1].stake)}
                </div>
              )}
            </div>
            <div className={styles.oddsContainer}>
              <div
                className={styles.odds}
                style={{ background: themeTokens.colorBgContainerDisabled }}
              >
                <img
                  src={logos[outcome0.book]}
                  alt={outcome0.book}
                  className={styles.bookLogo}
                />
                <div
                  className={styles.infoContainer}
                  style={{
                    background: themeTokens.colorBgContainerDisabled,
                    color: themeTokens.colorText,
                  }}
                >
                  {!isMoneyline && !isYesNo && (
                    <div className={styles.qualifier}>
                      {outcome0.points}
                      {!outcome0.isMainMarketOutcome && "*"}
                    </div>
                  )}
                  <span className={styles.info}>
                    {formatOdds(outcome0.odds)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.competitorContainer}>
            <div className={styles.competitorName}>
              <div>
                {isOverUnder ? "Under" : isYesNo ? "No" : participants[1]}
              </div>
              {outcomes[0].stake && (
                <div className={styles.betAmount}>
                  {usdFormatter.format(outcomes[0].stake)}
                </div>
              )}
            </div>
            <div className={styles.oddsContainer}>
              <div
                className={styles.odds}
                style={{ background: themeTokens.colorBgContainerDisabled }}
              >
                <img
                  src={logos[outcome1.book]}
                  alt={outcome1.book}
                  className={styles.bookLogo}
                />
                <div
                  className={styles.infoContainer}
                  style={{
                    background: themeTokens.colorBgContainerDisabled,
                    color: themeTokens.colorText,
                  }}
                >
                  {!isMoneyline && !isYesNo && (
                    <div className={styles.qualifier}>
                      {outcome1.points}
                      {!outcome1.isMainMarketOutcome && "*"}
                    </div>
                  )}
                  <span className={styles.info}>
                    {formatOdds(outcome1.odds)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LowHoldPlay;
